$mobile: 767px;
$tablet: 1023px;
$desktop: 1024px;
$xl: 1200px;
$xxl: 1400px;
$report: 1900px;
:root {
    --vh: 100%;

    
    --ndb-color-text-base: #fff;
    --ndb-color-text-inactive: #717171;
    --ndb-color-text-comment: #949399;
    --ndb-color-text-primary: #0b84ff;
    --ndb-color-text-secondary: #ff9a00;
    --ndb-color-text-secondary-dark: #c06905;
    --ndb-color-text-warn: #fb0000;

    --ndb-color-background: #1c1c1e;
    --ndb-color-background-base: #717171;
    --ndb-color-background-button-hover: #252527;
    --ndb-color-background-secondary: #2b2c2e;
    --ndb-color-background-header: #212025;
    --ndb-color-background-primary: #153b60;
    --ndb-color-background-primary50: rgb(21 59 96 / 50%);
    --ndb-color-background-item-hover: rgba(255, 255, 255, 0.1);

    --ndb-color-background-orange: #ff9a00;
    --ndb-color-background-orange-dark: #2e231d;
    --ndb-color-background-blue-dark: rgba(21, 59, 96, 0.4901960784);
    --ndb-color-background-red-dark: #2c1d1f;

    --ndb-color-border-base: #494a4c;
    --ndb-color-border-secondary: #2c2c2d;

    --border-xlight: hsla(0, 0%, 100%, 0.05);
    --border-light: hsla(0, 0%, 100%, 0.1);
    --border-medium: hsla(0, 0%, 100%, 0.15);
    --border-heavy: hsla(0, 0%, 100%, 0.2);
    --border-xheavy: hsla(0, 0%, 100%, 0.25);
    --border-sharp: hsla(0, 0%, 100%, 0.05);

    .light {
        --ndb-color-text-base: #000000;
        --ndb-color-text-inactive: #999999;
        --ndb-color-text-comment: #777777;
        --ndb-color-text-primary: #0b84ff;
        --ndb-color-text-secondary: #ff9a00;
        --ndb-color-text-secondary-dark: #c06905;
        --ndb-color-text-warn: #fb0000;

        --ndb-color-background: #ffffff;
        --ndb-color-background-base: #f1f1f1;
        --ndb-color-background-button-hover: #e6e6e6;
        --ndb-color-background-secondary: #f7f7f7;
        --ndb-color-background-header: #f0f0f0;
        --ndb-color-background-primary: #edf5ff;
        --ndb-color-background-primary50: rgba(237, 245, 255, 0.5);
        --ndb-color-background-item-hover: rgba(0, 0, 0, 0.05);

        --ndb-color-background-orange: #ff9a00;
        --ndb-color-background-orange-dark: #fce7d9;
        --ndb-color-background-blue-dark: rgba(150, 190, 235, 0.5);
        --ndb-color-background-red-dark: #fce7e9;

        --ndb-color-border-base: #dadada;
        --ndb-color-border-secondary: #e0e0e0;

        --border-xlight: rgb(0 0 0/5%);
        --border-light: rgba(0, 0, 0, 0.1);
        --border-medium: rgba(0, 0, 0, 0.15);
        --border-heavy: rgba(0, 0, 0, 0.2);
        --border-xheavy: rgba(0, 0, 0, 0.25);
    }

    --ndb-border-radius-base: 8px;
}
