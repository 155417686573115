.layout-container {
    background-color: var(--gray100);
    .layout {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: calc(var(--vh, 1vh) * 100);
        color: black;
        max-width: 600px;
        min-height: 100vh;
        margin: 0 auto;
        // background-color: var(--gray100);
        background-color: var(--ndb-color-background-secondary);

        // box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;

        .main-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 50px;
            max-width: 600px;
            width: 100%;
            padding: 16px;
            text-align: center;
            z-index: 100;
            background-color: white;
            // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

            .company-name {
                font-size: 17px;
                font-weight: 600;
            }
            .right-placeholder {
                width: 24px;
            }
        }

        .main-content {
            flex: 1;
            &.exist-header {
                padding-top: 48px;
            }
        }

        .main-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 64px 16px;
            background-color: var(--reverseThemeColor);
            color: var(--reverseTextBase);
        }
    }
}
