@font-face {
    font-family: 'Pretendard-Regular';
    src: url('../assets/fonts/Pretendard-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-Bold';
    src: url('../assets/fonts/Pretendard-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-SemiBold';
    src: url('../assets/fonts/Pretendard-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-Medium';
    src: url('../assets/fonts/Pretendard-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-Light';
    src: url('../assets/fonts/Pretendard-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-Thin';
    src: url('../assets/fonts/Pretendard-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-ExtraLight';
    src: url('../assets/fonts/Pretendard-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-ExtraBold';
    src: url('../assets/fonts/Pretendard-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}
