body {
    font-family: 'Pretendard-Regular', sans-serif;
}

* {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

*::-webkit-scrollbar {
    display: none;
}
