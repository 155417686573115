.common-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

    .page-button {
        display: flex;
        margin: 0 5px;
        background-color: var(--gray100);
        border-radius: 5px;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        color: var(--gray700);

        &:disabled {
            background-color: var(--gray100);
        }

        &.active-page {
            color: white !important;
            font-weight: bold;
            background-color: var(--gray400);
        }

        .ellipsis-text {
            font-size: 16;
            margin: 0 5px;
        }
    }
}

.dropdown {
    display: inline-block;

    &-trigger {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: transparent;
        cursor: pointer;
        gap: 4px;
    }

    &-menu {
        position: absolute;
        min-width: 100px;
        margin-top: 4px;
        background: white;
        border: 1px solid #ccc;
        border-radius: 6px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 10;
        list-style: none;
        padding: 0;
        right: 0;
    }

    &-item {
        padding: 12px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        white-space: nowrap;

        &:hover {
            background: #f0f0f0;
        }

        &.selected {
            font-weight: bold;
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--gray200);
        }
    }

    .dropdown-chevron {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;

        &.expanded {
            transform: rotate(360deg);
        }
    }
}

.common-modal {
    &-full-screen {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vh;
        max-width: 600px;
        background: var(--themeColor);
        z-index: 1000;
        padding: 20px;
        animation: fadeIn 0.3s ease-out forwards;
        .close-btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
        }
        .modal-content {
            flex: 1;
            max-height: calc(100vh - 112px);
            overflow-y: auto;
        }
    }
    &-horizontal-scroll {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: var(--themeColor);
        z-index: 1000;
        padding: 20px 0;
        transform: translateY(10px);
        animation: fadeIn 0.3s ease-out forwards;
        display: flex;
        flex-direction: column;

        .close-btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            padding-right: 20px;
        }

        .modal-content {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-top: 16px;
            overflow-x: auto;

            .fixed-column {
                background-color: var(--themeColor);
                z-index: 2;
                min-width: 120px;
                border-right: 1px solid var(--gray200);
                box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
                .cell {
                    text-align: left !important;
                }
            }

            .scrollable-content {
                flex: 1;
                overflow-x: auto;
                white-space: nowrap;
                .cell {
                    text-align: right !important;
                }
            }
            .cell {
                padding: 8px 12px;
                border-bottom: 1px solid var(--gray200);
                text-align: right !important;
            }
            .header {
                border-top: 1px solid var(--gray200);
            }
            .header-text {
                color: var(--gray700) !important;
            }
            .value {
                color: var(--gray800) !important;
            }
        }
    }

    @keyframes fadeIn {
        from {
            visibility: hidden;
            transform: translate(-50%, -50%) scale(0.95);
        }
        to {
            visibility: visible;
            transform: translate(-50%, -50%) scale(1);
        }
    }
    .modal-content {
        max-height: 90vh;
        width: 85%;
        max-width: calc(600px * 0.85);
        background-color: white;
        border-radius: 10px;
        padding: 16px;
        overflow-y: auto;
        position: relative;

        .close-btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 16px;
            z-index: 50;
        }
    }
}

.modal-overlay {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.common-table {
    width: 100%;
    border-collapse: collapse;

    thead,
    tfoot {
        border-top: 1px solid var(--gray300);
        border-bottom: 1px solid var(--gray300);
        background-color: var(--gray50);
    }

    th,
    td {
        vertical-align: middle;
    }

    td {
        padding: 4px;
    }

    .divider {
        border-bottom: 1px solid #ddd;
    }
}

.common-input {
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    outline: none;
    transition: border-color 0.2s ease-in-out;

    &:focus {
        border: 1px solid #6b7280;
    }
}

.common-tooltip {
    position: relative;
    display: inline-block;

    &-icon {
        cursor: pointer;
    }

    &-content {
        position: absolute;
        top: 25px;
        left: 0;
        background-color: var(--themeColor);
        border: 1px solid var(--gray200);
        color: var(--gray800);
        padding: 12px;
        border-radius: 4px;
        font-size: 12px;
        max-width: 200px;
        width: max-content;
        z-index: 10;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
        white-space: pre-line;
        word-break: keep-all;
    }
}
